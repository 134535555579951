import NotificationSounds, { playSampleSound } from 'react-native-notification-sounds'

export default function useNotificationSound() {
  //const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms))

  function playAlertSound() {
    try {
      NotificationSounds.getNotifications('notification')
        .then(soundsList => {
          playSampleSound(soundsList[1])
        })
        .catch(console.error)
    } catch (e) {
      console.error(e)
    }
  }
  function playConfirmSound() {
    try {
      NotificationSounds.getNotifications('notification')
        .then(soundsList => {
          playSampleSound(soundsList[15])
          /*
          for (let i = 0; i < soundsList.length; i++) {
            console.warn('SOUND', i, soundsList[i])
            playSampleSound(soundsList[i])
            await delay(3000)
          }
            */
        })
        .catch(console.error)
    } catch (e) {
      console.error(e)
    }
  }

  function playWarningSound() {
    try {
      NotificationSounds.getNotifications('notification')
        .then(soundsList => {
          playSampleSound(soundsList[11])
        })
        .catch(console.error)
    } catch (e) {
      console.error(e)
    }
  }
  return { playAlertSound, playConfirmSound, playWarningSound }
}
