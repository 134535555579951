import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import api from '../../apis/apiCalls'
import { StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import { SelectionViewProps } from '../../types'
import StockPositionSelectionView from './StockPositionSelectionView'

export default function StockPositionAvailabilitySelectionView({
  depositId,
  articleId,
  ...props
}: SelectionViewProps<StockPositionAvailability> & { depositId?: string; articleId?: string }) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const loader = useLoadingIndicator()

  function onSelected(stockPos: StockPosition) {
    loader.setLoading(true)
    api
      .getStockPositionAvailability({ stockPositionId: stockPos.id, articleId: articleId })
      .then(result => {
        if (result && result.length > 0) props.onSelected(result[0])
        else failedToLoad(stockPos.code)
      })
      .catch(() => {
        failedToLoad(stockPos.code)
      })
      .finally(() => {
        loader.setLoading(false)
      })
  }

  function failedToLoad(code: string) {
    alert.error(i18n.t('NoStockPosWithCode'), code)
  }

  return (
    <StockPositionSelectionView
      onSelected={onSelected}
      allowEmptyInput={props.allowEmptyInput}
      showResultInModal={props.showResultInModal}
      depositId={depositId}
    />
  )
}
