import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useEffect, useMemo } from 'react'

import api from '../../apis/apiCalls'
import { PackingList } from '../../apis/apiTypes'
import PackingListCard from '../../cards/Packinglist/PackingListCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import useInputHistory from '../../hooks/useInputHistory'
import useModalController from '../../hooks/useModalController'
import PackingListSelectionFilterModal from '../../modals/PackingListSelectionFilterModal'
import { SelectionViewProps } from '../../types'
import packingListUtils from '../../utils/packingListUtils'

export default function PackingListSelectionView(
  props: {
    preConsignment?: boolean
    productionConsignment?: boolean
    allowPreloading?: boolean
    preLoadedData?: PackingList[]
    filterResultByOrder?: boolean
    loadList?: boolean
    onMultiSelect?: (packingLists: PackingList[]) => void
  } & SelectionViewProps<PackingList>
) {
  const { i18n } = useLanguage()

  const { preConsignment, productionConsignment, allowPreloading, preLoadedData, filterResultByOrder, loadList, ...inputProps } = props
  const { history, add: addHistoryItem } = useInputHistory<PackingList>(
    preConsignment ? 'PackingListPreConsignment' : productionConsignment ? 'PackingListProductionConsignment' : 'PackingList'
  )
  const lastUsed = useMemo(() => history.map(h => h.item), [history])

  const packingListSelectionModal = useModalController<PackingList[]>()

  useEffect(() => {
    if (!loadList) return
    api
      .getPackingList({
        preConsignment: preConsignment,
        productionConsignment: productionConsignment,
      })
      .then(result => {
        if (!result.length) return
        packingListSelectionModal.show(result)
      })
      .catch(console.error)
  }, [loadList])

  function load(input: ScannerInputResult) {
    if (preLoadedData) return Promise.resolve<PackingList[]>(props.preLoadedData ?? [])
    return new Promise<PackingList[]>((resolve, reject) => {
      api
        .getPackingList({
          id: input.scannerInput || input.textInput || '',
          preConsignment: preConsignment,
          productionConsignment: productionConsignment,
        })
        .then(result => {
          resolve((result ?? []).sort(packingListUtils.sortPackingLists))
        })
        .catch(reject)
    })
  }

  function handlePackingListSelection(selectedItems: PackingList[]) {
    if (!selectedItems.length) return
    if (props.onMultiSelect) {
      props.onMultiSelect(selectedItems)
      return
    }
    addHistoryItem(selectedItems[0].id, selectedItems[0])
    props.onSelected(selectedItems[0])
  }

  function handleShowListResult(result: PackingList[]) {
    if (!result.length) return

    packingListSelectionModal.show(result)
  }

  const RenderItem = useCallback((item: PackingList) => <PackingListCard packingList={item} showDate />, [])

  return (
    <>
      <MWS_Input
        type={'PackingList'}
        title={i18n.t('SelectPackingList')}
        load={load}
        clearResultsOnScreenFocus
        itemRenderer={RenderItem}
        handleShowListResult={filterResultByOrder ? handleShowListResult : undefined}
        preload={
          allowPreloading ? () => api.getPackingList({ preConsignment: preConsignment, productionConsignment: productionConsignment }) : undefined
        }
        {...inputProps}
      />
      <PackingListSelectionFilterModal
        controller={packingListSelectionModal}
        multiSelection={!!props.onMultiSelect}
        onSelected={handlePackingListSelection}
        lastUsed={lastUsed}
        type={preConsignment ? 'PreConsignment' : productionConsignment ? 'ProductionConsignment' : 'Consignment'}
      />
    </>
  )
}
