import { useLanguage, View } from '@infominds/react-native-components'
import React from 'react'

import { ArticleInfoPrice } from '../../apis/apiTypes'
import TextRow from '../../components/TextRow'
import { articleUtils } from '../../utils/articleUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export interface ArticlePriceCardProps {
  price: ArticleInfoPrice
}

export default function ArticlePriceCard(props: ArticlePriceCardProps) {
  const { i18n } = useLanguage()

  return (
    <MWS_BaseCard>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <TextRow
          textStyle={{ margin: 0 }}
          texts={[
            { text: props.price.priceKey, style: { textAlign: 'left' } },
            { text: props.price.vatIncluded ? i18n.t('YES') : i18n.t('NO'), style: { textAlign: 'center' } },
            {
              text: articleUtils.currencyFormat(props.price.price, props.price.currency),
              style: { textAlign: 'right' },
            },
          ]}
        />
      </View>
    </MWS_BaseCard>
  )
}
