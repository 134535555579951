import { ClientProvider } from '@infominds/react-api'

import {
  PartsForExtractionRequest,
  PartsForTransferRequest,
  PartsOfMasterProductionRequest,
  PartToPreproductionPatchRequest,
  ProductionIncomingPostRequest,
  ProductionListRequest,
  ProductionOutgoingPostRequest,
  ProductionPartExtractionPatchRequest,
  ProductionPartPostRequest,
  ProductionPartsBookedRequest,
  ProductionPartsMovePatchRequest,
  ProductionPartsRequest,
  ProductionPreProductionPostRequest,
  ProductionPrintPostRequest,
  ProductionRequest,
  ProductionsEmployeeTimeTypesRequest,
} from './apiRequestTypes'
import {
  Production,
  ProductionMaster,
  ProductionPart,
  ProductionPartBooked,
  ProductionPartForExtraction,
  ProductionPartForTransfer,
  ProductionPartOfMasterProduction,
  ProductionsEmployeeTimeType,
} from './apiTypes'

export const production = ClientProvider<Production, ProductionRequest>('productions')
export const productionList = ClientProvider<Production[], ProductionListRequest>('productions/list')
export const productionMaster = ClientProvider<ProductionMaster[]>('productions/master')
export const productionParts = ClientProvider<ProductionPart[], ProductionPartsRequest>('productions/parts')
export const productionPartsBooked = ClientProvider<ProductionPartBooked[], ProductionPartsBookedRequest>('productions/partsbooked')
export const productionPartsOfMaster = ClientProvider<ProductionPartOfMasterProduction[], PartsOfMasterProductionRequest>(
  'productions/partsOfMasterProduction'
)
export const productionPart = ClientProvider<unknown, unknown, ProductionPart, ProductionPartPostRequest>('productions/part')
export const productionIncoming = ClientProvider<unknown, unknown, boolean, ProductionIncomingPostRequest>('productions/incoming')
export const productionOutgoing = ClientProvider<unknown, unknown, boolean, ProductionOutgoingPostRequest>('productions/outgoing')
export const productionPreProduction = ClientProvider<unknown, unknown, boolean, ProductionPreProductionPostRequest>(
  'productions/createpreproduction'
)
export const productionPartsMove = ClientProvider<unknown, unknown, unknown, unknown, boolean, ProductionPartsMovePatchRequest>(
  'productions/partsmove'
)
export const productionPartToPreproduction = ClientProvider<unknown, unknown, unknown, unknown, boolean, PartToPreproductionPatchRequest>(
  'productions/partToPreproduction'
)
export const productionPartsForExtraction = ClientProvider<ProductionPartForExtraction[], PartsForExtractionRequest>('productions/partsForExtraction')
export const productionPartExtraction = ClientProvider<unknown, unknown, unknown, unknown, boolean, ProductionPartExtractionPatchRequest>(
  'productions/partExtraction'
)
export const productionPartsForTransfer = ClientProvider<ProductionPartForTransfer[], PartsForTransferRequest>('productions/partsForTransfer')
export const productionPrint = ClientProvider<unknown, unknown, boolean, ProductionPrintPostRequest>('productions/print')

export const productionsEmployeeTimeTypes = ClientProvider<ProductionsEmployeeTimeType[], ProductionsEmployeeTimeTypesRequest>(
  'productions/employeeTimeTypes'
)
