import { ClientProvider } from '@infominds/react-api'

import { Company } from '../types'
import {
  AdditionalFieldPostRequest,
  AdditionalFieldRequest,
  CustomerAndDeliveriesForFinalPickingRequest,
  CustomersRequest,
  FreighterRequest,
  ItemAvailabilityRequest,
  ItemRequest,
  ItemStockPositionQuantitiesRequest,
  JobExecParameterRequest,
  ParcelPatchRequest,
  ParcelRequest,
  PurchaseOrderProposalPostRequest,
  ShoppingCartDeleteRequest,
  ShoppingCartPostRequest,
  ShoppingCartRequest,
  TipRequest,
  WorksheetConfirmPostRequest,
  WorksheetRequest,
  WorksheetSerialNumbersRequest,
} from './apiRequestTypes'
import {
  AdditionalField,
  Customer,
  CustomerDeliveryAddress,
  Freighter,
  Item,
  ItemStockPositionQuantity,
  Menu,
  Parcel,
  ShoppingCartEntry,
  UserSettings,
  Worksheet,
  WorksheetSerialNumbers,
} from './apiTypes'
import {
  articleAvailability,
  articleBarcode,
  articleConfigurableDescription,
  articleDeposit,
  articleDescription,
  articleGraphic,
  articleMeasurementUnit,
  articleOrder,
  articlePrice,
  articlePrices,
  articles,
  articleSalesPrice,
  articleSalesPriceScale,
  articleStockPosition,
} from './articles'
import {
  collo,
  colloConclude,
  colloNumberForFinalPickingPackaging,
  colloNumberShippingData,
  colloPackaging,
  colloTransfer,
  colloUnload,
  colloUnloadedPackagingList,
  colloWeight,
} from './collo'
import {
  inventoryAccounting,
  inventoryAccountings,
  inventoryCode,
  inventoryOngoing,
  inventoryOngoings,
  inventoryOngoingsToConfirm,
} from './inventory'
import { packagingList, packagingListConclude, packagingMovement, packagingMovements, packagingShipping, packagingType } from './packaging'
import {
  production,
  productionIncoming,
  productionList,
  productionMaster,
  productionOutgoing,
  productionPart,
  productionPartExtraction,
  productionParts,
  productionPartsBooked,
  productionPartsForExtraction,
  productionPartsForTransfer,
  productionPartsMove,
  productionPartsOfMaster,
  productionPartToPreproduction,
  productionPreProduction,
  productionPrint,
  productionsEmployeeTimeTypes,
} from './production'
import { quality, qualityCharacteristic } from './quality'
import {
  serialNumber,
  serialNumberAvailability,
  serialNumberCheck,
  serialNumberManufactorNumberCheck,
  serialNumberOrders,
  serialNumberQuantity,
} from './serialNumber'
import { filterConfig } from './utils'
import {
  deposit,
  stockAccounting,
  stockPosition,
  stockPositionAvailability,
  stockPositionAvailabilityWithSupplier,
  stockPositionProposal,
  stockPositionQuantity,
  stockPositionQuantityForKanban,
  stockTemplate,
} from './warehouse'

const additionalFields = ClientProvider<AdditionalField[], AdditionalFieldRequest, string, AdditionalFieldPostRequest>('additionalFields')
const parcel = ClientProvider<Parcel, ParcelRequest, unknown, unknown, boolean, ParcelPatchRequest>('parcel')
const shoppingCart = ClientProvider<
  ShoppingCartEntry[],
  ShoppingCartRequest,
  string,
  ShoppingCartPostRequest,
  unknown,
  unknown,
  string,
  unknown,
  ShoppingCartDeleteRequest
>('shoppingCart')

const api = {
  //article
  getArticle: articles.GET,
  getArticleAvailability: articleAvailability.GET,
  getArticleStockPositionData: articleStockPosition.GET,
  getArticleDescription: articleDescription.GET,
  getArticleConfigurableDescription: articleConfigurableDescription.GET,
  getArticleOrders: articleOrder.GET,
  getArticlePrice: articlePrice.GET,
  getArticlePrices: articlePrices.GET,
  getArticleSalesPrice: articleSalesPrice.GET,
  getArticleSalesPriceScale: articleSalesPriceScale.GET,
  getArticleGraphic: articleGraphic.GET,
  getArticleDepositData: articleDeposit.GET,
  patchArticleMeasurementUnit: articleMeasurementUnit.PATCH,
  postArticleBarcode: articleBarcode.POST,
  postArticleDepositData: articleDeposit.POST,
  patchArticleDepositData: articleDeposit.PATCH,

  //collos
  getCollo: collo.GET,
  postCollo: collo.POST,
  deleteCollo: collo.DELETE,
  concludeCollo: colloConclude.POST,
  colloPacking: colloPackaging.POST,
  getColloNumberForFinalPicking: colloNumberForFinalPickingPackaging.GET,
  postColloNumberShippingData: colloNumberShippingData.POST,
  postTransferCollo: colloTransfer.POST,
  getColloUnloadPackingList: colloUnloadedPackagingList.GET,
  unloadCollo: colloUnload.POST,
  getColloWeight: colloWeight.GET,

  //company
  getCompanies: ClientProvider<Company[]>('company').GET,

  //customers
  getCustomers: ClientProvider<Customer[], CustomersRequest>('customers').GET,
  getCustomerAndDeliveriesForFinalPicking: ClientProvider<CustomerDeliveryAddress[], CustomerAndDeliveriesForFinalPickingRequest>(
    'customers/andDeliveriesForFinalPicking'
  ).GET,

  //freighter
  getFreighter: ClientProvider<Freighter[], FreighterRequest>('freighter').GET,

  //inventory
  getInventoryCode: inventoryCode.GET,
  getInventoryAccounting: inventoryAccountings.GET,
  postInventory: inventoryAccounting.POST,
  deleteInventory: inventoryAccounting.DELETE,
  postOngoingInventory: inventoryOngoing.POST,
  patchOngoingInventory: inventoryOngoing.PATCH,
  getOngoingInventories: inventoryOngoings.GET,
  getOngoingInventoriesToConfirm: inventoryOngoingsToConfirm.GET,

  //item
  getItem: ClientProvider<Item[], ItemRequest>('items').GET,
  getItemAvailability: ClientProvider<number, ItemAvailabilityRequest>('items/availability').GET,
  getItemStockPositionQuantities: ClientProvider<ItemStockPositionQuantity[], ItemStockPositionQuantitiesRequest>('items/stockPositionQuantities')
    .GET,

  //menu
  getMenu: ClientProvider<Menu[]>('menue').GET,

  //packingLists
  getPackingList: packagingList.GET,
  patchPackingList: packagingList.PATCH,
  getPackagingType: packagingType.GET,
  postPackingListConclude: packagingListConclude.POST,
  getPackingListMovements: packagingMovements.GET,
  postPackingListShipping: packagingShipping.POST,
  addPackingListMovement: packagingMovement.POST,
  patchPackingListMovement: packagingMovement.PATCH,

  //production
  getProduction: production.GET,
  getProductionList: productionList.GET,
  getProductionsMaster: productionMaster.GET,
  getProductionParts: productionParts.GET,
  getProductionPartsBooked: productionPartsBooked.GET,
  getPartsOfMasterProduction: productionPartsOfMaster.GET,
  getPartsForExtraction: productionPartsForExtraction.GET,
  getPartsForTransfer: productionPartsForTransfer.GET,
  getProductionsEmployeeTimeTypes: productionsEmployeeTimeTypes.GET,
  postProductionPart: productionPart.POST,
  postProductionIncoming: productionIncoming.POST,
  postProductionOutgoing: productionOutgoing.POST,
  postCreatePreProduction: productionPreProduction.POST,
  patchProductionPartsMove: productionPartsMove.PATCH,
  patchPartToPreProduction: productionPartToPreproduction.PATCH,
  patchProductionPartExtraction: productionPartExtraction.PATCH,
  postProductionPrint: productionPrint.POST,

  //serialNumbers
  getSerialnumber: serialNumber.GET,
  postSerialnumber: serialNumber.POST,
  patchSerialnumber: serialNumber.PATCH,
  getSerialNumberAvailability: serialNumberAvailability.GET,
  checkSerialnumberChecks: serialNumberCheck.GET,
  getSerialNumberOrders: serialNumberOrders.GET,
  getSerialnumberQuantities: serialNumberQuantity.GET,
  checkSerialnumberManufactorNumberCheck: serialNumberManufactorNumberCheck.GET,

  //shoppingCart
  getShoppingCart: shoppingCart.GET,
  postShoppingCart: shoppingCart.POST,
  deleteShoppingCartEntry: shoppingCart.DELETE,

  //userSettings
  getUserSettings: ClientProvider<UserSettings>('userSettings').GET,

  //wareHouse
  getDeposit: deposit.GET,
  getStockPosition: stockPosition.GET,
  getStockTemplate: stockTemplate.GET,
  getStockPositionAvailability: stockPositionAvailability.GET,
  getStockPositionQuantities: stockPositionQuantity.GET,
  getStockPositionAvailabilitiesWithMainSuppliers: stockPositionAvailabilityWithSupplier.GET,
  postStockAccounting: stockAccounting.POST,
  patchStockAccounting: stockAccounting.PATCH,
  deleteStockAccounting: stockAccounting.DELETE,
  getStockPositionProposal: stockPositionProposal.GET,
  getStockPositionQuantityForKanbanTransfer: stockPositionQuantityForKanban.GET,
  getFilterConfig: filterConfig.GET,

  //worksheet
  getWorksheet: ClientProvider<Worksheet, WorksheetRequest>('worksheet').GET,
  getWorksheetSerialNumbers: ClientProvider<WorksheetSerialNumbers[], WorksheetSerialNumbersRequest>('worksheet/serialNumbers').GET,
  postWorksheetConfirm: ClientProvider<unknown, unknown, boolean, WorksheetConfirmPostRequest>('worksheet/confirm').POST,

  //additionalFields
  getAdditionalFields: additionalFields.GET,
  postAdditionalField: additionalFields.POST,

  //purchaseOrderProposal
  postPurchaseOrderProposal: ClientProvider<unknown, unknown, string, PurchaseOrderProposalPostRequest>('purchase/purchaseOrderProposal').POST,

  //tip
  getTip: ClientProvider<string, TipRequest>('tip').GET,

  //qualityManagement
  getQualityManagementCharacteristics: qualityCharacteristic.GET,
  getQualityControl: quality.GET,
  postQualityControl: quality.POST,
  patchQualityControl: quality.PATCH,
  deleteQualityControl: quality.DELETE,

  // JobExecParameter
  jobExecParameter: ClientProvider<unknown, unknown, string, JobExecParameterRequest>('jobExecParameter').POST,

  //parcel
  getParcel: parcel.GET,
  patchParcel: parcel.PATCH,
}

export default api
