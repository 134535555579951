import api from '../apis/apiCalls'
import { TextNumberType } from '../apis/apiRequestTypes'
import {
  ArticleAvailabilityControlType,
  ArticleDepositQuantity,
  ArticleDescription,
  ArticleDTO,
  ArticleInfoPrice,
  ArticleMeasurementUnit,
  ArticleOrder,
  Customer,
  Deposit,
  InventoryQuantityInsertType,
  MeasurementUnitType,
  QuantityInsertType,
  SerialNumber,
  StockPosition,
  StockPositionAvailability,
} from '../apis/apiTypes'
import { articleUtils } from '../utils/articleUtils'
import { serialnumberUtils } from '../utils/serialnumberUtils'
import { Utils } from '../utils/Utils'

export type ArticleOptions = {
  useSalesUnit?: boolean
  forceUseSalesUnit?: boolean
  usePurchasingUnit?: boolean
  useUnitType?: MeasurementUnitType | InventoryQuantityInsertType | QuantityInsertType
  serialNumber?: SerialNumber
  isHistoricalSerialNumberActive?: boolean
  useUnit?: ArticleMeasurementUnit | string
  isVerifySerialNumber?: boolean
}

export class Article {
  public info: ArticleDTO
  public useSalesUnit: boolean
  public usePurchasingUnit: boolean
  public mainUnit: ArticleMeasurementUnit
  public salesUnit?: ArticleMeasurementUnit
  public purchasingUnit?: ArticleMeasurementUnit
  public useUnit?: ArticleMeasurementUnit
  public depositQuantities?: ArticleDepositQuantity[]
  public serialNumberQuantities?: StockPositionAvailability[]
  public serialNumbers?: SerialNumber[]
  public quantity?: number
  public price?: number
  public image?: string
  public descriptionForOffer?: ArticleDescription[]
  public orders?: ArticleOrder[]
  public serialNumber?: SerialNumber
  public isSerialNumberActive: boolean
  public isLottoSerialNumber: boolean
  public isHistoricalSerialNumber: boolean
  public hasStock: boolean
  public autoCreateSerialNumbers: boolean

  public constructor(article: ArticleDTO, options?: ArticleOptions) {
    this.info = { ...article }
    this.isSerialNumberActive = articleUtils.isSerialNumberActive(this.info, options?.isHistoricalSerialNumberActive, options?.isVerifySerialNumber)
    this.serialNumber = this.isSerialNumberActive ? options?.serialNumber : undefined
    this.isHistoricalSerialNumber =
      !!this.isSerialNumberActive && !!options?.isHistoricalSerialNumberActive && !!this.info.isSerialnumberOnlyForHistory
    this.isLottoSerialNumber = this.isSerialNumberActive && this.info.isLottoSerialnumber
    this.autoCreateSerialNumbers = articleUtils.autoCreateSerialNumbersForArticle(article)
    this.hasStock = !!article.isStockmovement

    // units
    this.useSalesUnit =
      !!options?.forceUseSalesUnit || (!this.isSerialNumberActive && (!!options?.useSalesUnit || articleUtils.isSalesType(options?.useUnitType)))
    this.usePurchasingUnit =
      !this.isSerialNumberActive && !this.useSalesUnit && (!!options?.usePurchasingUnit || articleUtils.isPurchasingType(options?.useUnitType))
    this.mainUnit = articleUtils.getDefaultUnit(article)
    this.salesUnit = articleUtils.getSalesUnit(article)
    this.purchasingUnit = articleUtils.getPurchasingUnit(article)
    this.useUnit = undefined
    if (options?.useUnit) {
      if (typeof options?.useUnit === 'object') {
        this.useUnit = options?.useUnit
      } else if (typeof options?.useUnit === 'string') {
        const foundUnitWithIdOrCode = this.info.measurementUnits.find(u => u.id === options?.useUnit || u.code === options?.useUnit)
        if (foundUnitWithIdOrCode) {
          this.useUnit = foundUnitWithIdOrCode
        }
      }
    }
  }

  public loadAvailability(useUserDepositFilter?: boolean, depositId?: string, stockPositionId?: string) {
    return new Promise<boolean>((resolve, reject) => {
      let requestPromise
      if (this.serialNumber?.id && !this.isHistoricalSerialNumber) {
        requestPromise = api.getSerialNumberAvailability({
          serialNumberId: this.serialNumber.id,
          useUserDepositFilter: useUserDepositFilter,
          depositId: depositId,
        })
      } else {
        requestPromise = api.getArticleAvailability({
          articleId: this.info.id,
          useUserDepositFilter: useUserDepositFilter,
          depositId: depositId,
          stockPositionId: stockPositionId,
        })
      }

      requestPromise
        .then(articleAvailability => {
          if (articleAvailability) {
            Object.assign(this.info, { ...articleAvailability.article, ...this.info })
            this.depositQuantities = articleAvailability.depositquantities
            this.quantity = articleAvailability.quantity
            resolve(true)
          } else {
            reject('Failed to load Availability')
          }
        })
        .catch(reject)
    })
  }

  public loadSerialNumbers() {
    return new Promise<boolean>((resolve, reject) => {
      api
        .getSerialnumber({ articleId: this.info.id })
        .then(serialNumbers => {
          if (serialNumbers) {
            this.serialNumbers = Utils.keepUniques(serialNumbers ?? [], item => item.id).sort(serialnumberUtils.sort)
            resolve(true)
          } else {
            reject('Failed to load SerialNumbers')
          }
        })
        .catch(reject)
    })
  }

  public loadConfigurableDescription() {
    return new Promise<string>((resolve, reject) => {
      api
        .getArticleConfigurableDescription({ id: this.info.id })
        .then(description => resolve(description))
        .catch(reject)
    })
  }

  public loadPrices() {
    return new Promise<ArticleInfoPrice[]>((resolve, reject) => {
      api
        .getArticlePrices({ articleId: this.info.id })
        .then(prices => resolve(prices))
        .catch(reject)
    })
  }

  public loadSerialNumberQuantities(
    deposit?: Deposit,
    stockPosition?: StockPosition,
    serialNumber?: SerialNumber,
    filter?: (serial: StockPositionAvailability) => boolean,
    considerPickedQuantity?: boolean,
    loadWithoutStockPositionIfEmptyWithStockposition?: boolean,
    readQuantitiesForCalculationAvailability?: boolean
  ) {
    return new Promise<boolean>((resolve, reject) => {
      api
        .getSerialnumberQuantities({
          articleId: this.info.id,
          depositId: deposit?.id,
          stockPositionId: stockPosition?.id,
          serialnumberId: serialNumber?.id ?? this.serialNumber?.id,
          considerPickedQuantity: considerPickedQuantity,
          readQuantitiesForCalculationAvailability: readQuantitiesForCalculationAvailability,
        })
        .then(serialNumberQuantities => {
          if (serialNumberQuantities && serialNumberQuantities.length > 0) {
            if (filter) this.serialNumberQuantities = serialNumberQuantities.filter(filter)
            else this.serialNumberQuantities = serialNumberQuantities
            resolve(true)
          } else {
            if (loadWithoutStockPositionIfEmptyWithStockposition) {
              api
                .getSerialnumberQuantities({
                  articleId: this.info.id,
                  depositId: deposit?.id,
                  serialnumberId: serialNumber?.id ?? this.serialNumber?.id,
                  considerPickedQuantity: considerPickedQuantity,
                  readQuantitiesForCalculationAvailability: readQuantitiesForCalculationAvailability,
                })
                .then(serialNumberQuantities2 => {
                  if (serialNumberQuantities2 && serialNumberQuantities2.length > 0) {
                    if (filter) this.serialNumberQuantities = serialNumberQuantities2.filter(filter)
                    else this.serialNumberQuantities = serialNumberQuantities2
                    resolve(true)
                  } else {
                    reject('Failed to load SerialNumberQuantities')
                  }
                })
                .catch(reject)
            } else {
              reject('Failed to load SerialNumberQuantities')
            }
          }
        })
        .catch(reject)
    })
  }

  public loadImage() {
    return new Promise<string>(resolve => {
      if (this.info.grafikid) {
        api
          .getArticleGraphic({ id: this.info.grafikid })
          .then(image => {
            this.image = image
            resolve(image)
          })
          .catch(() => {
            resolve('')
          })
      } else {
        resolve('')
      }
    })
  }

  public loadSalesPrice(customer?: Customer, measurementUnitId?: string, quantity?: number) {
    return new Promise<boolean>(resolve => {
      api
        .getArticleSalesPrice({ articleId: this.info.id, customerId: customer?.id, measurementUnitId: measurementUnitId, quantity: quantity })
        .then(price => {
          this.price = price.price
          if (price.discount1 > 0) this.price = this.price - (this.price * price.discount1) / 100
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }

  public loadPrice(customer?: Customer) {
    return new Promise<boolean>(resolve => {
      api
        .getArticlePrice({ articleId: this.info.id, customerId: customer?.id })
        .then(value => {
          this.price = value
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }

  public loadOfferDescription() {
    return new Promise<boolean>(resolve => {
      api
        .getArticleDescription({ articleId: this.info.id, textTyp: TextNumberType.Offer })
        .then(value => {
          this.descriptionForOffer = value
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }

  public loadOrders() {
    return new Promise<boolean>(resolve => {
      let requestPromise
      if (this.serialNumber?.id) requestPromise = api.getSerialNumberOrders({ serialNumberId: this.serialNumber.id })
      else requestPromise = api.getArticleOrders({ articleId: this.info.id })

      requestPromise
        .then(orders => {
          this.orders = orders
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }

  public getTitle() {
    return this.info.code + ' - ' + this.info.searchtext
  }

  public getDescription(langId: string) {
    if (!this.info.descriptions) return this.info.searchtext
    return this.info.descriptions.find(d => d?.language?.toLowerCase()?.trim() === langId?.toLowerCase())?.text ?? this.info.searchtext
  }

  public getDescriptionForOffer(langId: string) {
    if (!this.descriptionForOffer || this.descriptionForOffer.length === 0) return this.getDescription(langId)
    return this.descriptionForOffer.find(d => d?.language?.toLowerCase()?.trim() === langId?.toLowerCase())?.text ?? this.getDescription(langId)
  }

  public getStockPositions(filterQuantityNoQuantity?: boolean) {
    if (!this.depositQuantities) return []
    const stockPositions: StockPositionAvailability[] = []
    this.getConvertedDepositQuantities().forEach(item => {
      if (item.stockpositionquantities) {
        item.stockpositionquantities.forEach(stockPos => {
          const foundIndex = stockPositions.findIndex(s => s.stockposition?.id === stockPos.stockposition?.id)
          if (foundIndex >= 0) {
            stockPositions[foundIndex].quantity = (stockPositions[foundIndex].quantity ?? 0) + (stockPos.quantity ?? 0)
            stockPositions[foundIndex].quantityCommissioned =
              (stockPositions[foundIndex].quantityCommissioned ?? 0) + (stockPos.quantityCommissioned ?? 0)
          } else {
            stockPos.deposit = item.deposit
            if (stockPos.stockposition) stockPos.stockposition.deposit = item.deposit
            stockPositions.push(stockPos)
          }
        })
      }
    })
    return stockPositions.filter(item => !filterQuantityNoQuantity || (item.quantity && item.quantity > 0))
  }

  public getQuantity(quantity?: number) {
    return this.convertQuantity(quantity ?? this.quantity ?? 0)
  }

  public getQuantityWithUnitText(quantity?: number, isAlreadyConverted?: boolean) {
    if (isAlreadyConverted) {
      return articleUtils.formatQuantity(quantity ?? 0, this.getUsedUnit())
    }
    return articleUtils.formatQuantity(this.getQuantity(quantity), this.getUsedUnit())
  }

  public getNetQuantity() {
    return Utils.sum(this.getConvertedDepositQuantities(), item => articleUtils.getArticleDepositNetQuantity(item))
  }

  public getSerialQuantity() {
    if (!this.serialNumberQuantities) return 0
    return this.serialNumberQuantities.reduce((total, serial) => total + (serial.quantity ?? 0), 0)
  }

  public getOrders() {
    return this.orders ?? []
  }

  public getConvertedOrders() {
    return this.getOrders().map(o => {
      return { ...o, quantity: this.convertQuantity(o.quantity) }
    })
  }

  public setUsedUnit(unit: ArticleMeasurementUnit | string | undefined) {
    if (typeof unit === 'string') {
      this.useUnit = articleUtils.getUnitById(this.info, unit)
      return
    }

    this.useUnit = unit
  }

  public getUsedUnit() {
    if (this.useUnit) return this.useUnit
    if (this.useSalesUnit && this.salesUnit) return this.salesUnit
    if (this.usePurchasingUnit && this.purchasingUnit) return this.purchasingUnit
    return this.mainUnit
  }

  public getUsedUnitText() {
    const usedUnit = this.getUsedUnit()
    if (usedUnit && usedUnit.code !== this.mainUnit.code) {
      return usedUnit.code + ` (${usedUnit.conversation} ${this.mainUnit.code})`
    }
    return this.mainUnit.code
  }

  public getUnitText(unit?: ArticleMeasurementUnit) {
    const useUnit = unit ?? this.getUsedUnit()
    if (useUnit && useUnit.code !== this.mainUnit.code) {
      return useUnit.code + ` (${useUnit.conversation} ${this.mainUnit.code})`
    }
    return unit?.code ?? ''
  }

  public convertQuantity(quantity: number) {
    return articleUtils.convertToUnit(quantity, this.getUsedUnit())
  }

  public getConvertedDepositQuantities(filterQuantityNoQuantity?: boolean) {
    if (!this.depositQuantities || this.depositQuantities.length === 0) return []
    return this.depositQuantities
      .map(item => this.convertDepositQuantity(item))
      .sort((a, b) => (a.deposit.code > b.deposit.code ? 1 : -1))
      .filter(
        item =>
          !filterQuantityNoQuantity ||
          !!item.quantity ||
          !!item.quantityCommissioned ||
          !!item.quantityOrdered ||
          !!item.quantityPurchasing ||
          !!item.quantityFreeInputs
      )
  }

  public getConvertedSerialNumberQuantities(conversionUnit?: ArticleMeasurementUnit, filterEmpty?: boolean) {
    const unit = conversionUnit ?? this.getUsedUnit()
    if (!this.serialNumberQuantities) return []
    return (
      this.serialNumberQuantities
        .map<StockPositionAvailability>(q => ({
          ...q,
          quantity: (q.quantity ?? 0) * unit.conversation,
          quantityCommissioned: (q.quantityCommissioned ?? 0) * unit.conversation,
        }))
        .filter(q => !filterEmpty || (q.quantity && q.quantity > 0)) ?? []
    )
  }

  private convertDepositQuantity(dq: ArticleDepositQuantity) {
    const result: ArticleDepositQuantity = { ...dq }
    result.quantity = this.convertQuantity(result.quantity)
    result.quantityCommissioned = this.convertQuantity(result.quantityCommissioned)
    result.quantityOrdered = this.convertQuantity(result.quantityOrdered)
    result.quantityPurchasing = this.convertQuantity(result.quantityPurchasing)
    result.quantityFreeInputs = this.convertQuantity(result.quantityFreeInputs)
    result.stockpositionquantities = result.stockpositionquantities
      .map(item => this.convertStockPositionQuantity(item))
      .sort((a, b) => ((a.stockposition?.code ?? 0) > (b.stockposition?.code ?? 0) ? 1 : -1))
    return result
  }

  private convertStockPositionQuantity(stPos: StockPositionAvailability) {
    const result: StockPositionAvailability = { ...stPos }
    result.quantity = this.convertQuantity(result.quantity ?? 0)
    result.quantityCommissioned = this.convertQuantity(result.quantityCommissioned ?? 0)
    return result
  }

  public getPriceText(useUnitConversation: boolean) {
    if (this.price !== undefined) {
      let priceToCalc = this.price
      // Umrechnen auf die Maßeinheit
      if (useUnitConversation && this.useUnit?.conversation !== undefined && this.useUnit?.conversation !== 0) {
        priceToCalc = this.price * this.useUnit?.conversation
      }

      return articleUtils.formatQuantity(priceToCalc.toString(), '€')
    }
    return ''
  }

  public getQuantityBySerial(serialNumber?: SerialNumber) {
    const useSerial = serialNumber ?? this.serialNumber
    if (!this.serialNumberQuantities || !useSerial) return undefined
    const results = this.serialNumberQuantities.filter(item => item.serialnumber?.id === useSerial.id)
    if (results.length === 1) return results[0]
    return undefined
  }

  public isAvailabilityCheckEnabled() {
    return this.info.availabilityControl !== ArticleAvailabilityControlType.NoControl
  }
}
