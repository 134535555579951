import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import { ArticleSalesPriceScale } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { articleUtils } from '../../utils/articleUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export interface ArticleSalesPriceScaleCardProps {
  priceScale: ArticleSalesPriceScale
  unit: string
}

export default function ArticleSalesPriceScaleCard(props: ArticleSalesPriceScaleCardProps) {
  const { i18n } = useLanguage()

  function getDiscountText() {
    let result = `${i18n.t('AB')} ${props.priceScale.quantity} ${props.unit}`
    if (props.priceScale.discount1 > 0) result = `${result} - ${props.priceScale.discount1} %`
    if (props.priceScale.discount2 > 0) result = `${result} - ${props.priceScale.discount2} %`
    return `${result} = ${articleUtils.currencyFormat(props.priceScale.priceForSinglePieceWithDiscount, '€')}`
  }

  return (
    <MWS_BaseCard>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}>
        <IMText>{getDiscountText()}</IMText>
      </View>
    </MWS_BaseCard>
  )
}
