import { useLanguage, Utils } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo, useState } from 'react'

import api from '../../../apis/apiCalls'
import { ArticleSerialNumberCreationType, Production } from '../../../apis/apiTypes'
import ProductionCard from '../../../cards/Production/ProductionCard'
import { Article } from '../../../classes/Article'
import ProductionData from '../../../classes/ProductionData'
import MWS_Screen from '../../../components/MWS/MWS_Screen'
import SwitchWithText from '../../../components/SwitchWithText'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import { useLoadingIndicator } from '../../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../../contexts/UserSettingsContext'
import useAlert from '../../../hooks/useAlert'
import useRouteParam from '../../../hooks/useRouteParam'
import useToastMessage from '../../../hooks/useToastMessage'
import { ProductionType, ScreenType } from '../../../types'
import { ArticleAmountSelectionResult } from '../../../views/Article/ArticleAmountSelectorV2'
import MultiSelectionView from '../../../views/SerialNumber/MultiSelectionView'

export default function ProductionInScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const toastMessage = useToastMessage()
  const userSettings = useUserSettings()

  const production = useRouteParam<Production>('production')
  const article = useRouteParam<Article>('article')
  const quantityFromProductionOut = useRouteParam<number>('quantityFromProductionOut')
  const [concludeProductionSwitch, setConcludeProductionSwitch] = useState(
    !quantityFromProductionOut || quantityFromProductionOut === production?.quantityOpen
  )
  const loader = useLoadingIndicator()
  const allowedSerialNumbers = useMemo(() => {
    if (!production?.orderSerialnumberquantities?.length) return undefined
    return production.orderSerialnumberquantities.map(o => o.serialnumber)
  }, [])

  function navigateToProductionOut(quantityFromProductionIn: number) {
    if (!production) return
    const productionHandler = new ProductionData(production)
    loader.setLoading(true)
    Promise.all([productionHandler.loadParts(true, userSettings), productionHandler.loadStockTemplates()])
      .then(() => {
        // check available
        const partsNotAvailable =
          productionHandler.parts?.filter(
            s =>
              s.part.deposit?.isStockpositionActive &&
              s.part.quantityStockpositionAvailable != null &&
              s.part.quantityStockpositionAvailable < s.part.quantity
          ) ?? []
        if (partsNotAvailable.length > 0) {
          alert
            .yesNo(i18n.t('NOTTHEENTIREQUANTITYAVAILABLEPROCEDE'))
            .then(answer => {
              if (answer) {
                navigation.navigate(ScreenType.ProductionOut, { productionHandler, quantityFromProductionIn: quantityFromProductionIn })
              }
            })
            .catch(console.error)
        } else {
          navigation.navigate(ScreenType.ProductionOut, { productionHandler, quantityFromProductionIn: quantityFromProductionIn })
        }
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message ?? i18n.t('FailedToLoadProductionParts'), production.code)
      })
      .finally(() => {
        loader.setLoading(false)
        navigation.navigate(ScreenType.ProductionSelection, { type: quantityFromProductionOut ? ProductionType.Out : ProductionType.In })
      })
  }

  function completeProduction(postItems: ArticleAmountSelectionResult[]) {
    if (!production || !postItems || postItems.length === 0) return
    loader.setLoading(true)
    api
      .postProductionIncoming({
        productionId: production.id,
        quantities: postItems.map(item => ({ quantity: item.quantity, serialNumber: item.serialNumber?.number })),
        completeProductionOrder: concludeProductionSwitch,
      })
      .then(() => {
        toastMessage.show(i18n.t('ProductionCompletedSuccessfully'))
        const quantityBooked = postItems.map(item => ({ quantity: item.quantity, serialNumber: item.serialNumber?.number }))
        if (userSettings?.createProductionOutgoingAfterIncoming && !!quantityBooked && !quantityFromProductionOut) {
          navigateToProductionOut(Utils.sum(quantityBooked, q => q.quantity))
        } else {
          navigation.navigate(ScreenType.ProductionSelection, { type: quantityFromProductionOut ? ProductionType.Out : ProductionType.In })
        }
      })
      .catch(error => {
        console.error(error)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        alert.info(i18n.t('ProductionCompletionFailure'), error?.Message)
      })
      .finally(() => {
        loader.setLoading(false)
      })
  }

  if (!production) {
    navigation.navigate(ScreenType.ProductionSelection, { type: quantityFromProductionOut ? ProductionType.Out : ProductionType.In })
    return <></>
  }

  function preNavigationCheck(type: 'goBack' | 'popToTop') {
    if (type !== 'goBack') return false
    navigation.navigate(ScreenType.ProductionSelection, { type: quantityFromProductionOut ? ProductionType.Out : ProductionType.In })
    return true
  }

  return (
    <MWS_Screen preNavigationCallback={preNavigationCheck}>
      <ProductionCard
        handler={production}
        endButton={true}
        showQuantities
        showQuantityToBeConfirmed
        requiredQuantity={quantityFromProductionOut ? `${quantityFromProductionOut?.toString()} ${production?.unitCode?.trim()}` : undefined}
      />
      <SwitchWithText
        text={i18n.t('COMPLETE_PRODUCTION')}
        value={concludeProductionSwitch}
        setValue={setConcludeProductionSwitch}
        style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
      />
      <MultiSelectionView
        article={article}
        onComplete={completeProduction}
        proposedQuantity={quantityFromProductionOut ?? production.quantityOpen}
        style={{ marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        listTitleProvider={items =>
          `${i18n.t('SelectSerialnumber')} (${Utils.sum(items, item => item.quantity)}/${quantityFromProductionOut ?? production.quantityOpen})`
        }
        confirmationButtonCaption={i18n.t('Confirm')}
        confirmationButtonDisabled={items => !!quantityFromProductionOut && Utils.sum(items, q => q.quantity) < quantityFromProductionOut}
        ignoreSerialNumber={article?.autoCreateSerialNumbers}
        ignoreAvailability
        allowedSerialNumbers={allowedSerialNumbers}
        errorSerialNotAllowedText={i18n.t('SerialNumberNotAllowedByOrder')}
        allowNewSerialCreation={article?.info.createSerialnumber === ArticleSerialNumberCreationType.No}
      />
    </MWS_Screen>
  )
}
