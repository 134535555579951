import { useInfomindsAnalyticsNavigation } from '@infominds/react-native-analytics'
import { useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import * as React from 'react'
import { StatusBar } from 'react-native'

import ArticleAvailabilityScreen from '../screens/Article/ArticleAvailabilityScreen'
import ArticleBarcodeArticleSelectionScreen from '../screens/Article/ArticleBarcodeArticleSelectionScreen'
import ArticleDepositDataArticleSelectionScreen from '../screens/Article/ArticleDepositDataArticleSelectionScreen'
import ArticleOrdersScreen from '../screens/Article/ArticleOrdersScreen'
import ArticleSalesPriceScaleScreen from '../screens/Article/ArticleSalesPriceScaleScreen'
import ArticleSelectionScreen from '../screens/Article/ArticleSelectionScreen'
import ArticleWeightArticleSelectionScreen from '../screens/Article/ArticleWeightArticleSelectionScreen'
import DoubleClickConfigScreen from '../screens/DoubleClickConfigScreen'
import HomeScreen from '../screens/HomeScreen'
import InventoryCreationScreen from '../screens/Inventory/InventoryCreationScreen'
import InventoryListScreen from '../screens/Inventory/InventoryListScreen'
import InventorySelectionScreen from '../screens/Inventory/InventorySelectionScreen'
import InventorySerialScreen from '../screens/Inventory/InventorySerialScreen'
import OngoingInventoryCreationScreen from '../screens/Inventory/OngoingInventoryCreationScreen'
import OngoingInventoryRecountingListScreen from '../screens/Inventory/OngoingInventoryRecountingListScreen'
import KanbanCreateListScreen from '../screens/Kanban/KanbanCreateListScreen'
import KanbanWorkListScreen from '../screens/Kanban/KanbanWorkListScreen'
import LoginScreen from '../screens/LoginScreen'
import OrderProposalCreationScreen from '../screens/OrderProposal/OrderProposalCreationScreen'
import OrderProposalDepositSelectionScreen from '../screens/OrderProposal/OrderProposalDepositSelectionScreen'
import OrderProposalScreen from '../screens/OrderProposal/OrderProposalScreen'
import ColloNumbersForFinalPickingScreen from '../screens/Packinglist/CollonumbersForFinalPickingScreen'
import PackingListCompletionScreen from '../screens/Packinglist/Completion/PackingListCompletionScreen'
import CustomerAndDeliveriesForFinalPickingScreen from '../screens/Packinglist/CustomerAndDeliveriesForFinalPickingScreen'
import PackingListScreen from '../screens/Packinglist/PackingListScreen'
import PackingListSelectionScreen from '../screens/Packinglist/PackingListSelectionScreen'
import PreConsignmentListSelectionScreen from '../screens/Packinglist/PreConsignment/PreConsignmentListSelectionScreen'
import ProductionConsignmentListSelectionScreen from '../screens/Packinglist/ProductionConsignmentListSelectionScreen'
import ParcelScreen from '../screens/Parcel/ParcelScreen'
import ParcelSelectionScreen from '../screens/Parcel/ParcelSelectionScreen'
import ProductionInScreen from '../screens/Production/IN/ProductionInScreen'
import ProductionOutScreen from '../screens/Production/Out/ProductionOutScreen'
import ProductionAllocationListSelectionScreen from '../screens/Production/ProductionAllocationListSelectionScreen'
import ProductionAllocationPreProductionScreen from '../screens/Production/ProductionAllocationPreProductionScreen'
import ProductionCreatePreProductionScreen from '../screens/Production/ProductionCreatePreProductionScreen'
import ProductionExtractionArticleScreen from '../screens/Production/ProductionExtractionArticleScreen'
import ProductionMoveArticleScreen from '../screens/Production/ProductionMoveArticleScreen'
import ProductionSelectionScreen from '../screens/Production/ProductionSelectionScreen'
import ProductionTransferScreen from '../screens/Production/ProductionTransferScreen'
import QualityManagementScreen from '../screens/QualityManagement/QualityManagementScreen'
import QualityManagementSelectionScreen from '../screens/QualityManagement/QualityManagementSelectionScreen'
import SerialnumberMacAddressScreen from '../screens/Serialnumber/SerialnumberMacAddressScreen'
import SerialnumberManufacturerNumberScreen from '../screens/Serialnumber/SerialnumberManufacturerNumberScreen'
import SerialnumberQuantitySelectionScreen from '../screens/Serialnumber/SerialnumberQuantitySelectionScreen'
import SerialnumberSelectionScreen from '../screens/Serialnumber/SerialnumberSelectionScreen'
import ShoppingCartCreationScreen from '../screens/ShoppingCart/ShoppingCartCreationScreen'
import ShoppingCartCustomerSelectionScreen from '../screens/ShoppingCart/ShoppingCartCustomerSelectionScreen'
import ShoppingCartScreen from '../screens/ShoppingCart/ShoppingCartScreen'
import StockPositionInfoScreen from '../screens/StockPosition/StockPositionInfoScreen'
import StockPositionSelectionScreen from '../screens/StockPosition/StockPositionSelectionScreen'
import StockPositionSerialScreen from '../screens/StockPosition/StockPositionSerialScreen'
import SubMenuScreen from '../screens/SubMenuScreen'
import TransferArticleSelectionScreen from '../screens/Transfer/TransferArticleSelectionScreen'
import TransferPositionSelectionScreen from '../screens/Transfer/TransferPositionSelectionScreen'
import TransferColloScreen from '../screens/TransferCollo/TransferColloScreen'
import WorksheetScreen from '../screens/Worksheet/WorksheetScreen'
import WorksheetSelectionScreen from '../screens/Worksheet/WorksheetSelectionScreen'
import { ScreenType } from '../types'

export const screenConfig = [
  { type: ScreenType.Root, showSettings: true, component: HomeScreen },
  { type: ScreenType.SubMenu, showSettings: true, component: SubMenuScreen },
  { type: ScreenType.DoubleClickConfig, component: DoubleClickConfigScreen },
  { type: ScreenType.ArticleSelection, component: ArticleSelectionScreen },
  { type: ScreenType.ArticleAvailability, component: ArticleAvailabilityScreen },
  { type: ScreenType.ArticleOrders, component: ArticleOrdersScreen },
  { type: ScreenType.ArticleSalesPriceScale, component: ArticleSalesPriceScaleScreen },
  { type: ScreenType.ArticleBarcodeArticleSelection, component: ArticleBarcodeArticleSelectionScreen },
  { type: ScreenType.ArticleDepositDataArticleSelection, component: ArticleDepositDataArticleSelectionScreen },
  { type: ScreenType.ArticleWeightArticleSelection, component: ArticleWeightArticleSelectionScreen },
  { type: ScreenType.StockPositionSelection, component: StockPositionSelectionScreen },
  { type: ScreenType.StockPositionInfo, component: StockPositionInfoScreen },
  { type: ScreenType.StockPositionSerial, component: StockPositionSerialScreen },
  { type: ScreenType.ShoppingCartCreation, component: ShoppingCartCreationScreen },
  { type: ScreenType.ShoppingCartCustomerSelection, component: ShoppingCartCustomerSelectionScreen },
  { type: ScreenType.ShoppingCart, component: ShoppingCartScreen },
  { type: ScreenType.SerialnumberSelection, component: SerialnumberSelectionScreen },
  { type: ScreenType.SerialnumberQuantitySelection, component: SerialnumberQuantitySelectionScreen },
  { type: ScreenType.SerialnumberManufacturerNumber, component: SerialnumberManufacturerNumberScreen },
  { type: ScreenType.SerialnumberMacAddress, component: SerialnumberMacAddressScreen },
  { type: ScreenType.InventorySelection, component: InventorySelectionScreen },
  { type: ScreenType.InventoryListScreen, component: InventoryListScreen },
  { type: ScreenType.InventoryCreation, component: InventoryCreationScreen },
  { type: ScreenType.InventorySerial, component: InventorySerialScreen },
  { type: ScreenType.OngoingInventoryCreation, component: OngoingInventoryCreationScreen },
  { type: ScreenType.OngoingInventoryRecounting, component: OngoingInventoryRecountingListScreen },
  { type: ScreenType.TransferCollo, component: TransferColloScreen },
  { type: ScreenType.PackingListSelection, component: PackingListSelectionScreen },
  { type: ScreenType.PackingList, component: PackingListScreen },
  { type: ScreenType.PackingListCompletion, component: PackingListCompletionScreen },
  { type: ScreenType.PreConsignmentListSelection, component: PreConsignmentListSelectionScreen },
  { type: ScreenType.ProductionConsignmentListSelection, component: ProductionConsignmentListSelectionScreen },
  { type: ScreenType.CustomerAndDeliveriesForFinalPicking, component: CustomerAndDeliveriesForFinalPickingScreen },
  { type: ScreenType.ColloNumbersForFinalPickingScreen, component: ColloNumbersForFinalPickingScreen },
  { type: ScreenType.ProductionSelection, component: ProductionSelectionScreen },
  { type: ScreenType.ProductionIn, component: ProductionInScreen },
  { type: ScreenType.ProductionOut, component: ProductionOutScreen },
  { type: ScreenType.ProductionMoveArticle, component: ProductionMoveArticleScreen },
  { type: ScreenType.ProductionExtractionArticle, component: ProductionExtractionArticleScreen },
  { type: ScreenType.ProductionTransferArticle, component: ProductionTransferScreen },
  { type: ScreenType.ProductionCreatePreProduction, component: ProductionCreatePreProductionScreen },
  { type: ScreenType.ProductionAllocationListSelection, component: ProductionAllocationListSelectionScreen },
  { type: ScreenType.ProductionAllocationPreProduction, component: ProductionAllocationPreProductionScreen },
  { type: ScreenType.TransferArticleSelection, component: TransferArticleSelectionScreen },
  { type: ScreenType.TransferPositionSelection, component: TransferPositionSelectionScreen },
  { type: ScreenType.WorksheetSelection, component: WorksheetSelectionScreen },
  { type: ScreenType.Worksheet, component: WorksheetScreen },
  { type: ScreenType.OrderProposalCreation, component: OrderProposalCreationScreen },
  { type: ScreenType.OrderProposalDepositSelection, component: OrderProposalDepositSelectionScreen },
  { type: ScreenType.OrderProposal, component: OrderProposalScreen },
  { type: ScreenType.QualityManagement, component: QualityManagementScreen },
  { type: ScreenType.QualityManagementSelection, component: QualityManagementSelectionScreen },
  { type: ScreenType.KanbanCreateList, component: KanbanCreateListScreen },
  { type: ScreenType.KanbanWorkList, component: KanbanWorkListScreen },
  { type: ScreenType.ParcelSelection, component: ParcelSelectionScreen },
  { type: ScreenType.Parcel, component: ParcelScreen },
]

const Stack = createNativeStackNavigator()

export default function Navigation() {
  const { colorScheme } = useTheme()
  const { isLoggedIn } = useAuthentication()

  const { navigationRef, onReady } = useInfomindsAnalyticsNavigation()

  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle={colorScheme === 'light' ? 'dark-content' : 'light-content'} />
      <NavigationContainer ref={navigationRef} theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme} onReady={onReady}>
        <Stack.Navigator initialRouteName="Login" screenOptions={{ headerShown: false, animation: 'slide_from_right' }}>
          <Stack.Group>
            {!isLoggedIn ? (
              <Stack.Screen name={ScreenType.Login.toString() ?? ''} component={LoginScreen} />
            ) : (
              <Stack.Group>
                {screenConfig.map(config => (
                  <Stack.Screen key={'StackScreen' + config.type?.toString()} name={config.type?.toString() ?? ''} component={config.component} />
                ))}
              </Stack.Group>
            )}
          </Stack.Group>
        </Stack.Navigator>
      </NavigationContainer>
    </>
  )
}
