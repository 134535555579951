import { useApi } from '@infominds/react-api'
import { useLanguage, Utils } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'

import api from '../../../apis/apiCalls'
import { ColloPacking, PackingListConclusionType } from '../../../apis/apiRequestTypes'
import { Collo, Freighter, PrintingType } from '../../../apis/apiTypes'
import { PackingListArticle } from '../../../classes/PackingListCollection'
import MWS_Screen from '../../../components/MWS/MWS_Screen'
import { useLoadingIndicator } from '../../../contexts/LoadingIndicatorContext'
import usePackingListHandler from '../../../hooks/specific/usePackingListHandler'
import useAlert from '../../../hooks/useAlert'
import useModalController from '../../../hooks/useModalController'
import useToastMessage from '../../../hooks/useToastMessage'
import ColloCompletionModal from '../../../modals/ColloCompletionModal'
import { ScreenType } from '../../../types'
import packingListUtils from '../../../utils/packingListUtils'
import PackingLisIncompleteMovementsView from '../../../views/PackingList/PackingLisIncompleteMovementsView'
import PackingListColloModeCompletionView from '../../../views/PackingList/PackingListColloModeCompletionView'
import PackingListCompletionView from '../../../views/PackingList/PackingListCompletionView'
import PackingListToComplete from '../../../views/PackingList/PackingListToComplete'

export default function PackingListCompletionScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const handler = usePackingListHandler()
  const loader = useLoadingIndicator()
  const toast = useToastMessage()
  const colloCompletionModal = useModalController<Collo[]>()
  const [packingTypes, loadPackingTypes] = useApi(api.getPackagingType, [])

  const anyPackingList = handler.packingLists?.length ? handler.packingLists[0] : undefined
  const [incompletePackingListMovements, setIncompletePackingListMovements] = useState<PackingListArticle[]>([])
  const [collosToConclude, setCollosToConclude] = useState<Collo[]>([])
  const [loaded, setLoaded] = useState(false)

  const canBeCompleted = loaded && incompletePackingListMovements.length === 0 && collosToConclude.length === 0 && !!handler.movements?.length
  // always show colloCompletionView if no packingTypes exists
  const colloModeCompletionView = handler.colloMode || !packingTypes?.length

  useEffect(() => {
    if (handler.isProductionConsignment) return
    if (handler.isPreConsignment && handler.packingLists) {
      //PreConsignment: conclude automatically
      loader.setLoading(true)

      const requests = handler.packingLists.map(pl => {
        return api.postPackingListConclude({
          packingListId: pl.id,
          conclusionTyp: handler.isProductionConsignment
            ? PackingListConclusionType.concludeAndCreateTransfer
            : PackingListConclusionType.preCommissioning,
          collaboratorId: handler.userSettings?.employeeId,
        })
      })
      Promise.allSettled(requests)
        .then(onConcludeDone)
        .catch(showErrorAlert)
        .finally(() => loader.setLoading(false))
    } else {
      loader.setLoading(true)
      loadPackingTypes()
        .catch(console.error)
        .finally(() => {
          handler
            .load()
            .then(movements => {
              // find incomplete movements
              const incompleteMovements = packingListUtils.getIncompleteMovements(movements).sort(packingListUtils.sortMovementBySortKey)
              setIncompletePackingListMovements(incompleteMovements)

              // find collos that need to be concluded
              if (handler.colloMode && !handler.isPreConsignment && !handler.isProductionConsignment) {
                const collosToBeConcluded = packingListUtils.getCollosFromPackingListArticles(movements, true, true)
                setCollosToConclude(collosToBeConcluded)
                if (incompleteMovements.length === 0 && collosToBeConcluded.length > 0) colloCompletionModal.show(collosToBeConcluded)
              }

              setLoaded(true)
            })
            .catch(reason => {
              console.error(reason)
              navigation.goBack()
            })
            .finally(() => {
              loader.setLoading(false)
            })
        })
    }
  }, [])

  async function concludePackingList(
    selectedFreighter: Freighter | undefined,
    colloCount: number | undefined,
    weight: number | undefined,
    printing: boolean | undefined,
    createDeliveryNote: boolean | undefined,
    collos?: ColloPacking[]
  ) {
    try {
      loader.setLoading(true)
      if (collos?.length) {
        const colloPackingRequest = {
          packinglistId: anyPackingList?.id ?? '',
          collaboratorId: handler.userSettings?.employeeId,
          printLabel: true,
          colli: collos,
        }
        // only if NOT collo consignment: send collos (packages) to be created
        await api.colloPacking(colloPackingRequest)
      }

      const request = {
        collaboratorId: handler.userSettings?.employeeId,
        conclusionTyp: PackingListConclusionType.concludeAndCreateTransfer,
        freighterId: selectedFreighter?.id,
        packages: colloCount,
        packingListId: anyPackingList?.id,
        weight: weight,
        printing: printing ? PrintingType.printing : PrintingType.notPrinting,
        createDeliveryNote: createDeliveryNote,
      }
      await api.postPackingListConclude(request)
      onConcludeDone()
    } catch (exception) {
      showErrorAlert(exception)
    } finally {
      loader.setLoading(false)
    }
  }

  function onConcludeDone() {
    toast.show(i18n.t('PackingListConclusionSuccessful'))

    if (!handler.isPreConsignment && !handler.isProductionConsignment && handler.userSettings?.isShowPackingListAfterConclude) {
      navigation.navigate(ScreenType.PackingListSelection, { loadList: true })
      return
    }

    navigation.navigate(
      handler.isPreConsignment
        ? ScreenType.PreConsignmentListSelection
        : handler.isProductionConsignment
          ? ScreenType.ProductionConsignmentListSelection
          : ScreenType.PackingListSelection,
      { loadList: false }
    )
  }

  function showErrorAlert(reason?: any) {
    console.error(reason)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    alert.error(i18n.t('FailedToConcludePackingList'), reason?.Message ?? '')
  }

  return (
    <MWS_Screen title={i18n.t('PackingListConclude')} hideGoBackButton={handler.isProductionConsignment}>
      {handler.isProductionConsignment && <PackingListToComplete />}
      {!handler.isProductionConsignment && (
        <ColloCompletionModal
          controller={colloCompletionModal}
          onAborted={() => {
            navigation.goBack()
          }}
          onCompleted={() => {
            setCollosToConclude([])
          }}
          deposit={Utils.getValueIfAllAreEqual(handler.packingLists, p => p.deposit?.id)?.deposit}
        />
      )}
      <PackingLisIncompleteMovementsView
        show={!handler.isProductionConsignment && incompletePackingListMovements.length > 0}
        incompletePackingListArticles={incompletePackingListMovements}
        onContinue={() => {
          setIncompletePackingListMovements([])
          if (collosToConclude.length) colloCompletionModal.show(collosToConclude)
        }}
      />
      <PackingListCompletionView // CompletionView if not ColloMode
        movements={handler.movements ?? []}
        show={!handler.isProductionConsignment && canBeCompleted && !colloModeCompletionView}
        handler={handler}
        conclude={concludePackingList}
      />
      <PackingListColloModeCompletionView // CompletionView if ColloMode
        show={!handler.isProductionConsignment && canBeCompleted && !!colloModeCompletionView}
        handler={handler}
        conclude={concludePackingList}
      />
    </MWS_Screen>
  )
}
