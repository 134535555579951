const prodDemo = {
  DEMO_USERNAME: 'rxmws',
  DEMO_PASSWORD: '123',
  DEMO_LICENSE_KEY: 'APP-RXMWS_0JO_DEMO_RN',
}

const devDemo = [
  {
    // 0 ACS Firma 003
    DEMO_USERNAME: 'rxmws',
    DEMO_PASSWORD: '123',
    DEMO_LICENSE_KEY: 'APP-RXMWS_DEMO',
  },
  {
    // 1 Zirkonzahn test
    DEMO_USERNAME: 'PDA2',
    DEMO_PASSWORD: '123',
    DEMO_LICENSE_KEY: 'APP-RXMWS_0EJ_TEST_IM_INTERNAL',
  },
  {
    // 2 Intercable test
    DEMO_USERNAME: 'MW',
    DEMO_PASSWORD: '789',
    DEMO_LICENSE_KEY: 'APP-RXMWS_059_RN_TEST',
  },
  {
    // 3 Nordwal test
    DEMO_USERNAME: 'TOBIAS',
    DEMO_PASSWORD: 'TobVerJulJak',
    DEMO_LICENSE_KEY: 'APP-RXMWS_084_N',
  },
  {
    // 4 ACS firma 0U8
    DEMO_USERNAME: 'rxmws',
    DEMO_PASSWORD: '123',
    DEMO_LICENSE_KEY: 'APP-RXMWS_0U8_DEMO',
  },
  {
    // 5 Micros
    DEMO_USERNAME: 'THOMASH',
    DEMO_PASSWORD: '676',
    DEMO_LICENSE_KEY: 'APP-RXMWS_1DH_IM',
  },
  {
    // 6 Mediflora
    DEMO_USERNAME: 'PDA1',
    DEMO_PASSWORD: 'pda',
    DEMO_LICENSE_KEY: 'APP-RXMWS_0JR_TESTIM',
  },
  {
    // 7 Salfer
    DEMO_USERNAME: 'LAG1',
    DEMO_PASSWORD: '',
    DEMO_LICENSE_KEY: 'APP-RXMWS_1OU_TEST',
  },
  {
    // 8 Interfama
    DEMO_USERNAME: '#acsservices',
    DEMO_PASSWORD: 'A77rCqP6EE3@',
    DEMO_LICENSE_KEY: 'APP-RXMWS_0T2',
  },
  {
    // 9 Radix-Demo ODI204
    DEMO_USERNAME: 'RADIX-DEMO',
    DEMO_PASSWORD: '123',
    DEMO_LICENSE_KEY: 'APP-RXMWS_0JO_ODI204',
  },
  {
    // 10 ACS
    DEMO_USERNAME: 'JURIE',
    DEMO_PASSWORD: '41595',
    DEMO_LICENSE_KEY: 'APP-RXMWS_012_M',
  },
  {
    // 11 Amonn Office
    DEMO_USERNAME: 'MANUELS',
    DEMO_PASSWORD: '1982',
    DEMO_LICENSE_KEY: 'APP-RXMWS_0FZ_N',
  },
  {
    // 12 Koch
    DEMO_USERNAME: 'VICENZA',
    DEMO_PASSWORD: 'vicenza',
    DEMO_LICENSE_KEY: 'APP-RXMWS_0GF',
  },
  {
    // 13 Amonn Profi Color
    DEMO_USERNAME: 'ANDREAS',
    DEMO_PASSWORD: '1234',
    DEMO_LICENSE_KEY: 'APP-RXMWS_0WE',
  },
  {
    // 14 Tecnomag
    DEMO_USERNAME: 'PDA-WOLFGANG',
    DEMO_PASSWORD: 'rx',
    DEMO_LICENSE_KEY: 'APP-RXMWS_013_RN',
  },
]

export default __DEV__ ? devDemo[0] : prodDemo
