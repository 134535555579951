import { ClientProvider } from '@infominds/react-api'

import {
  ArticleAvailabilityRequest,
  ArticleBarcodePostRequest,
  ArticleDepositDataPatchRequest,
  ArticleDepositDataPostRequest,
  ArticleDepositDataRequest,
  ArticleDescriptionRequest,
  ArticleGraphicRequest,
  ArticleMeasurementUnitPatchRequest,
  ArticleOrdersRequest,
  ArticlePriceRequest,
  ArticlePricesRequest,
  ArticleRequest,
  ArticleSalesPriceRequest,
  ArticleSalesPriceScaleRequest,
  ArticleStockPositionDataRequest,
  GetArticleConfigurableDescriptionRequest,
} from './apiRequestTypes'
import {
  ArticleAvailability,
  ArticleDepositData,
  ArticleDescription,
  ArticleDTO,
  ArticleInfoPrice,
  ArticleOrder,
  ArticleSalesPrice,
  ArticleSalesPriceScale,
  ArticleStockPositionData,
} from './apiTypes'

export const articles = ClientProvider<ArticleDTO[], ArticleRequest>('articles')
export const articleAvailability = ClientProvider<ArticleAvailability, ArticleAvailabilityRequest>('articles/availability')
export const articleStockPosition = ClientProvider<ArticleStockPositionData[], ArticleStockPositionDataRequest>('articles/stockPositionData')
export const articleDescription = ClientProvider<ArticleDescription[], ArticleDescriptionRequest>('articles/descriptions')
export const articleConfigurableDescription = ClientProvider<string, GetArticleConfigurableDescriptionRequest>('articles/configurabledescription')
export const articleOrder = ClientProvider<ArticleOrder[], ArticleOrdersRequest>('articles/orders')
export const articlePrice = ClientProvider<number, ArticlePriceRequest>('articles/price')
export const articlePrices = ClientProvider<ArticleInfoPrice[], ArticlePricesRequest>('articles/prices')
export const articleSalesPrice = ClientProvider<ArticleSalesPrice, ArticleSalesPriceRequest>('articles/salesprice')
export const articleSalesPriceScale = ClientProvider<ArticleSalesPriceScale[], ArticleSalesPriceScaleRequest>('articles/salespriceScale')
export const articleGraphic = ClientProvider<string, ArticleGraphicRequest>(
  'articles/grafic',
  result => `data:image/png;base64, ${result?.toString() ?? ''}`
)
export const articleDeposit = ClientProvider<
  ArticleDepositData[],
  ArticleDepositDataRequest,
  string,
  ArticleDepositDataPostRequest,
  string,
  ArticleDepositDataPatchRequest
>('articles/depositdata')
export const articleMeasurementUnit = ClientProvider<undefined, undefined, undefined, undefined, string, ArticleMeasurementUnitPatchRequest>(
  'articles/measurementUnit'
)
export const articleBarcode = ClientProvider<undefined, undefined, string, ArticleBarcodePostRequest>('articles/barcode')
