import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'

import api from '../../apis/apiCalls'
import { AdditionalActions } from '../../apis/apiRequestTypes'
import { Item, ItemStockPositionQuantity, OngoingInventory, OngoingInventoryToConfirm, StockPosition } from '../../apis/apiTypes'
import { serialNumber } from '../../apis/serialNumber'
import ArticleCard from '../../cards/Article/ArticleCard'
import StockPositionCard from '../../cards/StockPosition/StockPositionCard'
import { Article } from '../../classes/Article'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import useRouteParam from '../../hooks/useRouteParam'
import { Error_Utils } from '../../utils/ErrorUtils'
import ongoingInventoryUtils from '../../utils/ongoingInventoryUtils'
import { ArticleAmountSelectionResult } from '../../views/Article/ArticleAmountSelectorV2'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import StockPositionSelectionView from '../../views/InputViews/StockPositionSelectionView'
import MultiSelectionView from '../../views/SerialNumber/MultiSelectionView'

export default function OngoingInventoryCreationScreen() {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const userSettings = useUserSettings()
  const navigation = useNavigation()

  const [selectedStockPosition, setSelectedStockPosition] = useState<StockPosition | undefined>()
  const [ongoingInventoryStockPosition, setOngoingInventoryStockPosition] = useState<OngoingInventory | null>()
  const [ongoingInventoryArticle, setOngoingInventoryArticle] = useState<OngoingInventory | null>()
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>()

  const ongoingInventoryToConfirm = useRouteParam<OngoingInventoryToConfirm>('ongoingInventoryToConfirm')

  const loader = useLoadingIndicator()

  useEffect(() => {
    if (ongoingInventoryToConfirm) {
      setStockPositionWithConfirmationDate(ongoingInventoryToConfirm.stockposition, ongoingInventoryToConfirm.date)
    }
  }, [])

  function setStockPosition(value?: StockPosition) {
    setStockPositionWithConfirmationDate(value, ongoingInventoryToConfirm?.date)
  }

  function setStockPositionWithConfirmationDate(value?: StockPosition, confirmationDate?: string) {
    setSelectedStockPosition(value)
    if (!value) return
    ongoingInventoryUtils
      .getOngoingInventory(value.id, undefined, undefined, confirmationDate)
      .then(setOngoingInventoryStockPosition)
      .catch(console.error)
  }

  function onItemSelected(value: Item) {
    loader.setLoading(true)
    const article = new Article(value.article, { serialNumber: value.serialnumber })
    ongoingInventoryUtils
      .getOngoingInventory(selectedStockPosition?.id, value.article.id, undefined, ongoingInventoryToConfirm?.date)
      .then(setOngoingInventoryArticle)
      .catch(console.error)
    if (article.isSerialNumberActive && !article.isHistoricalSerialNumber) {
      article
        .loadSerialNumberQuantities(selectedStockPosition?.deposit, selectedStockPosition, undefined, undefined, false)
        .catch(console.error)
        .finally(() => {
          setSelectedArticle(article)
          loader.setLoading(false)
        })
    } else {
      article
        .loadAvailability(false, selectedStockPosition?.deposit.id, selectedStockPosition?.id)
        .catch(console.error)
        .finally(() => {
          setSelectedArticle(article)
          loader.setLoading(false)
        })
    }
  }

  async function runCompleteInventory(items: ArticleAmountSelectionResult[]) {
    if (!selectedStockPosition) return
    if (!selectedArticle) return

    try {
      loader.setLoading(true)
      if (!ongoingInventoryToConfirm) {
        await Promise.all(
          items.map(item =>
            api.postOngoingInventory(
              ongoingInventoryUtils.createPostRequest(selectedStockPosition, selectedArticle?.info, item.serialNumber, item.quantity)
            )
          )
        )
        setSelectedArticle(undefined)
        setStockPosition(selectedStockPosition)
      } else {
        await Promise.all(
          items.map(item =>
            api.patchOngoingInventory(
              ongoingInventoryUtils.createPatchRequest(
                ongoingInventoryToConfirm?.stockposition,
                selectedArticle?.info,
                ongoingInventoryToConfirm?.date,
                item.serialNumber,
                item.quantity
              )
            )
          )
        )
        navigation.goBack()
      }
    } catch (reason) {
      alert.error(i18n.t('FailedToCreateInventory'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  async function onClearStockposition() {
    if (!selectedStockPosition) return

    // Abfrage
    const result = await alert.yesNo(i18n.t('ONGOINGINVENTORYQUESTIONSET0FORALLARTICLE'))
    if (!result) return

    try {
      loader.setLoading(true)
      await api.postOngoingInventory(ongoingInventoryUtils.createPostRequest(selectedStockPosition, undefined, undefined, undefined))

      setSelectedArticle(undefined)
      setSelectedStockPosition(undefined)
    } catch (reason) {
      alert.error(i18n.t('FailedToCreateInventory'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  async function handleOnItemStockPositionQuantitiesSelected(items: ItemStockPositionQuantity[]) {
    if (!items || items.length === 0) return
    if (!selectedStockPosition) return

    try {
      loader.setLoading(true)
      if (!ongoingInventoryToConfirm) {
        for (const itemToBook of items) {
          await api.postOngoingInventory(
            ongoingInventoryUtils.createPostRequest(selectedStockPosition, itemToBook.article, itemToBook.serialNumber, itemToBook.quantity)
          )
        }
        setSelectedArticle(undefined)
        setSelectedStockPosition(undefined)
      } else {
        await Promise.all(
          items.map(item =>
            api.patchOngoingInventory(
              ongoingInventoryUtils.createPatchRequest(
                ongoingInventoryToConfirm?.stockposition,
                item?.article,
                ongoingInventoryToConfirm?.date,
                item.serialNumber,
                item.quantity
              )
            )
          )
        )
        navigation.goBack()
      }
    } catch (reason) {
      alert.error(i18n.t('FailedToCreateInventory'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  return (
    <MWS_Screen title={ongoingInventoryUtils.getTitle(ongoingInventoryToConfirm, i18n.t('OngoingInventory'), i18n.t('OngoingInventoryRecounting'))}>
      {!selectedStockPosition && <StockPositionSelectionView onSelected={setStockPosition} history />}
      {selectedStockPosition && !selectedArticle && (
        <>
          <StockPositionCard
            stockPosition={selectedStockPosition}
            deposit={selectedStockPosition.deposit}
            addInfo={ongoingInventoryUtils.getTextLastCount(
              i18n.t('NoOngoingInventory'),
              i18n.t('LastOngoingInventory'),
              ongoingInventoryStockPosition
            )}
          />
          {!ongoingInventoryToConfirm && (
            <SubmitButton
              style={{ margin: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN, marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }}
              title={i18n.t('ClearStockposition')}
              onPress={onClearStockposition}
            />
          )}
          {!userSettings?.isOngoingInventorySelectArticleSerialNumberActive && (
            <ItemSelectionView
              fetchArticleAndSerial
              onSelected={onItemSelected}
              additionalActions={[AdditionalActions.OngoingInventory]}
              showResultInModal
            />
          )}
          {userSettings?.isOngoingInventorySelectArticleSerialNumberActive && (
            <ItemSelectionView
              fetchArticleAndSerial
              loadOngoingInventoryItems={!!ongoingInventoryToConfirm}
              ongoingInventoryDate={ongoingInventoryToConfirm?.date}
              additionalActions={[AdditionalActions.OngoingInventory]}
              onSelected={onItemSelected}
              onItemStockPositionQuantitiesSelected={items => {
                handleOnItemStockPositionQuantitiesSelected(items).catch(console.error)
              }}
              allowEmptyInput
              depositId={selectedStockPosition.deposit.id}
              stockPositionId={selectedStockPosition.id}
              showNegativeQuantities
              showResultInModal
            />
          )}
        </>
      )}

      {selectedStockPosition && selectedArticle && (
        <>
          <StockPositionCard
            stockPosition={selectedStockPosition}
            deposit={selectedStockPosition.deposit}
            addInfo={ongoingInventoryUtils.getTextLastCount(
              i18n.t('NoOngoingInventory'),
              i18n.t('LastOngoingInventory'),
              ongoingInventoryStockPosition
            )}
          />
          <ArticleCard
            article={selectedArticle.info}
            maxLinesDescription={4}
            unit={selectedArticle.getUsedUnit()}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            addInfo={ongoingInventoryUtils.getTextLastCount(i18n.t('NoOngoingInventory'), i18n.t('LastOngoingInventory'), ongoingInventoryArticle)}
          />
          <MultiSelectionView
            article={selectedArticle}
            deposit={selectedStockPosition.deposit}
            stockPosition={selectedStockPosition}
            onComplete={items => {
              runCompleteInventory(items).catch(console.error)
            }}
            selectSerialsByPosition={!ongoingInventoryToConfirm}
            autoComplete
            hidePositionFromSelectedSerial
            hideAvailability
            checkAvailability
            ignoreAvailability
            proposedQuantity={1}
            showSelectedSerialsWithPosition={false}
            availabilityCheckFailedWorkaroundMessage={
              ongoingInventoryToConfirm
                ? i18n.t('ENTEREDQUANTITYDIFFERSFROMPREVIOUSINVENTORYQUANTITYCONTINUEANYWAY')
                : i18n.t('EnteredQuantityDiffersStronglyFromQuantityContinueAnyway')
            }
            availabilityTolerance={ongoingInventoryUtils.getAvailabilityTolerance(ongoingInventoryToConfirm, userSettings?.toleranceRunningInventory)}
          />
        </>
      )}
    </MWS_Screen>
  )
}
