import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useRef } from 'react'

import api from '../../apis/apiCalls'
import { Production, ProductionState } from '../../apis/apiTypes'
import ProductionCard from '../../cards/Production/ProductionCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useInputHistory from '../../hooks/useInputHistory'
import useModalController from '../../hooks/useModalController'
import ProductionListSelectionFilterModal from '../../modals/ProductionListSelectionFilterModal'
import { SelectionViewProps } from '../../types'

const inputKeys = { prod: 'PRODID=', employeeTimeTypeId: 'MITARBZEITVGID=' }

export default function ProductionSelectionView(
  props: {
    allowPreloading?: boolean
    filterResultByOrder?: boolean
  } & SelectionViewProps<Production>
) {
  const { i18n } = useLanguage()

  const { allowPreloading, filterResultByOrder, onSelected, ...otherProps } = props
  const info = useRef<string | undefined>()
  const { add: addHistoryItem } = useInputHistory<Production>('Production')
  const { setLoading } = useLoadingIndicator()

  const productionSelectionModal = useModalController<Production[]>()

  function load(inputResult: ScannerInputResult) {
    const input = inputResult.scannerInput ?? inputResult.textInput ?? ''
    const loadWithEmployee = checkForEmployee(input)
    info.current = loadWithEmployee?.employee
    if (loadWithEmployee) {
      return api.getProduction({ id: loadWithEmployee.prod })
    }
    return api.getProduction({ id: input })
  }

  function checkForEmployee(input: string) {
    if (!input.includes(';')) return undefined
    const terms = input.replace('\n', '').split(';')
    if (terms.length !== 2) return undefined
    let prod = ''
    let employee
    if (terms[1].startsWith(inputKeys.prod)) {
      prod = terms[1].replace(inputKeys.prod, '')
      employee = terms[0].replace(inputKeys.employeeTimeTypeId, '')
    } else {
      prod = terms[0].replace(inputKeys.prod, '')
      employee = terms[1].replace(inputKeys.employeeTimeTypeId, '')
    }
    return { prod, employee }
  }

  function handleProductionListSelection(selectedItems: Production[]) {
    if (!selectedItems.length) return
    addHistoryItem(selectedItems[0].id, selectedItems[0])
    props.onSelected(selectedItems[0])
  }

  async function handleShowListResult() {
    setLoading(true)
    const result = await api.getProductionList({ state: ProductionState.Open })
    if (!result.length) return

    productionSelectionModal.show(result)
  }

  const RenderItem = useCallback((item: Production) => <ProductionCard handler={item} />, [])

  return (
    <>
      <MWS_Input
        type={'Production'}
        title={i18n.t('SelectProduction')}
        failedToLoadText={i18n.t('NoProductionWithId')}
        modalTitle={i18n.t('SelectProduction')}
        onSelected={item => {
          onSelected(item, info.current)
          info.current = undefined
        }}
        load={load}
        loadFromHistory={item => load({ textInput: item.id })}
        clearResultsOnScreenFocus
        itemRenderer={RenderItem}
        handleShowListButtonPressed={
          filterResultByOrder
            ? () => {
                handleShowListResult()
                  .catch(console.error)
                  .finally(() => setLoading(false))
              }
            : undefined
        }
        preload={allowPreloading ? () => api.getProductionList({ state: ProductionState.Open }) : undefined}
        {...otherProps}
      />
      <ProductionListSelectionFilterModal controller={productionSelectionModal} onSelected={handleProductionListSelection} type={'Production'} />
    </>
  )
}
