import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'

import { InventoryEntry, StockPosition } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { ScreenType } from '../../types'
import StockPositionSelectionView from '../../views/InputViews/StockPositionSelectionView'
import InventorySelectionView from '../../views/Inventory/InventorySelectionView'

export default function InventorySelectionScreen() {
  const [inventory, setInventory] = useState<InventoryEntry>()
  const navigation = useNavigation()

  function setAndCheckInventory(selectedInventory: InventoryEntry) {
    if (!selectedInventory.deposit?.isStockpositionActive) {
      navigation.navigate(ScreenType.InventoryListScreen, { inventory: selectedInventory })
    } else {
      setInventory(selectedInventory)
    }
  }

  function selectedStockPosition(stockPosition: StockPosition | undefined) {
    navigation.navigate(ScreenType.InventoryListScreen, { inventory: inventory, stockPosition: stockPosition })
  }

  return (
    <MWS_Screen title={inventory?.description}>
      {!inventory && <InventorySelectionView onInventorySelected={setAndCheckInventory} />}
      {inventory && !!inventory.deposit?.isStockpositionActive && (
        <>
          <StockPositionSelectionView onSelected={selectedStockPosition} depositId={inventory.deposit.id} />
        </>
      )}
    </MWS_Screen>
  )
}
